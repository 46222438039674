import { useSelector, useDispatch } from "react-redux";
import { useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";

import Stack from "../Stack/Stack.js";
import { cardPickerUpdated } from "../../store/ui.js";
import { cardsSelected } from "../../store/cards.js";
import { WebSocketContext } from "../../WebSocket.js";
import { setUpCardPickerListeners } from "../../utils/drag.js";

import "./CardPicker.css";

const CardPicker = () => {
    const { open, title, body, options, targetAmount } = useSelector(
        (state) => state.ui.cardPicker
    );
    const cards = useSelector((state) => state.entities.cards.all);
    const selected = useSelector((state) => state.entities.cards.selected);
    const ws = useContext(WebSocketContext);
    const { playerId, propertySets, bankCards, bankId } = ws.getCashDrawer();
    const currAmount = selected
        .map((cardId) => cards[cardId].value)
        .reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        }, 0);

    const dispatch = useDispatch();

    const ackAction = (selected) => {
        ws.ackAction(null, null, null, selected);
    };

    const onCardClick = (cardId) => {
        if (selected.includes(cardId)) {
            dispatch({
                type: cardsSelected.type,
                payload: selected.filter((x) => x !== cardId),
            });
        } else {
            dispatch({
                type: cardsSelected.type,
                payload: [...new Set([...selected, cardId])],
            });
        }
    };

    const handleClose = async (selected, requestCallback) => {
        await dispatch({
            type: cardPickerUpdated.type,
            payload: {
                open: false,
            },
        });
        if (requestCallback) ackAction(selected);
    };

    useEffect(() => {
        setUpCardPickerListeners();
    });

    return (
        <Dialog
            className="card-picker"
            open={open}
            onClose={() => false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"100%"}
        >
            {title ? (
                <div>
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                </div>
            ) : null}
            <div className="property-sets">
                {
                    /* Displaying property sets of the player */
                    propertySets && propertySets.length
                        ? propertySets.map((set) =>
                              set.id ? (
                                  <Stack
                                      key={set.id}
                                      setId={set.id}
                                      playerId={playerId}
                                      type={"vertical"}
                                      cards={set.cards}
                                      expanded={false}
                                      onCardClick={onCardClick}
                                  />
                              ) : null
                          )
                        : null
                }
            </div>
            {
                /* Displaying bank cards of the player */ bankCards &&
                bankCards &&
                bankCards.length ? (
                    <Stack
                        setId={bankId}
                        playerId={playerId}
                        type={"horizontal"}
                        cards={bankCards}
                        cash={false}
                        expanded={false}
                        onCardClick={onCardClick}
                    />
                ) : null
            }
            <div>
                {options ? (
                    <DialogActions>
                        <DialogContentText id="alert-dialog-description">
                            {body}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-payload">
                            {"$" +
                                (currAmount ? currAmount : 0) +
                                "M / $" +
                                targetAmount +
                                "M"}
                        </DialogContentText>
                        <div className="action-btn-container">
                            {options.map((option) => (
                                <div
                                    className="dialog-btn"
                                    key={option.id}
                                    onClick={() =>
                                        currAmount >= targetAmount
                                            ? handleClose(
                                                  option.id,
                                                  option.requestCallback
                                              )
                                            : toast(
                                                  `Please select cards worth at least $${targetAmount}M.`
                                              )
                                    }
                                >
                                    {option.name}
                                </div>
                            ))}
                        </div>
                    </DialogActions>
                ) : null}
            </div>
        </Dialog>
    );
};

export default CardPicker;
