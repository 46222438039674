import React, { createContext, useEffect, useRef } from "react";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { cardsFetched, cardsSelected } from "./store/cards.js";
import {
    actionReceived,
    privateDataFetched,
    publicDataFetched,
} from "./store/game.js";
import { cardPickerUpdated, dialogUpdated } from "./store/ui.js";
import { peersFetched } from "./store/peers.js";
import { getAJNCards, getADRCards } from "./utils/card.js";

export const WebSocketContext = createContext();

const WebSocketProvider = (props) => {
    const socketRef = useRef(null);
    const actionState = useRef(null);
    const reactionState = useRef(null);
    const allCards = useSelector((state) => state.entities.cards.all);
    const selectedCards = useSelector((state) => state.entities.cards.selected);
    const game = useSelector((state) => state.entities.game.public);
    const privateData = useSelector((state) => state.entities.game.private);
    const action = useSelector((state) => state.entities.game.action);

    const dispatch = useDispatch();

    const _playCard = async () => {
        const { card_id, play_as, payload } = actionState.current;
        await socketRef.current.emit(
            "action",
            { card_id, play_as, payload },
            (response) => {
                toast(response.message);
                console.log(response);
            }
        );
        actionState.current = null;
    };

    const _playCashCard = async () => {
        await _playCard();
    };

    const _doRearrangemnt = async () => {
        const { card_id, play_as, payload } = actionState.current;
        await socketRef.current.emit(
            "action",
            { card_id, play_as, payload },
            (response) => {
                console.log(response);
                toast(response.message);
            }
        );
        actionState.current = null;
    };

    const _rearrangeCard = async (setId, selected) => {
        if (!actionState.current.step) actionState.current.step = 1;
        else actionState.current.step += 1;

        switch (actionState.current.step) {
            case 1:
                const type = allCards[actionState.current.card_id].type;
                if (type === "PROPERTY_WILDCARD" || type === "PROPERTY") {
                    /* The card belongs to a property set or hand */
                    await dispatch({
                        type: dialogUpdated.type,
                        payload: {
                            open: true,
                            collapsable: true,
                            cardCode: actionState.current.cardCode,
                            options: [
                                { id: "SET", name: "Select Set" },
                                {
                                    id: "COLOR",
                                    name: "Create New Set",
                                    requestCallback: true,
                                },
                            ],
                        },
                    });
                } else {
                    /* The card belongs to bank or hand */
                    await _doRearrangemnt();
                }
                break;
            case 2:
                // Save input from step 1.
                switch (selected) {
                    case "SET":
                        actionState.current = {
                            ...actionState.current,
                            step: actionState.current.step + 1,
                            payload: {
                                set: { set_id: setId },
                            },
                        };
                        await _doRearrangemnt();
                        break;
                    case "COLOR":
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: actionState.current.cardCode,
                                options: allCards[
                                    actionState.current.card_id
                                ].colors.map((color) => ({
                                    id: color,
                                    name: color,
                                    requestCallback: true,
                                })),
                            },
                        });
                        break;
                    default:
                        console.log(
                            "Something went wrong!",
                            actionState.current
                        );
                }
                break;
            case 3:
                actionState.current = {
                    ...actionState.current,
                    payload: {
                        set: { color: selected },
                    },
                };
                await _doRearrangemnt();
                break;
            default:
                console.log("Something went wrong!", actionState.current);
        }
    };

    const _discardCard = async () => {
        await socketRef.current.emit(
            "action",
            {
                card_ids: [actionState.current.card_id],
                play_as: actionState.current.play_as,
            },
            (response) => {
                console.log(response);
                toast(response.message);
            }
        );
        actionState.current = null;
    };

    const _playActionCard = async (
        cardId,
        cardCode,
        setId,
        playerId,
        selected
    ) => {
        if (!actionState.current.step) actionState.current.step = 1;
        else actionState.current.step += 1;

        switch (actionState.current.cardCode) {
            /* Basic Property Cards */
            case "PBr":
            case "PDb":
            case "PLg":
            case "PBl":
            case "POr":
            case "PRd":
            case "PDg":
            case "PPk":
            case "PYl":
            case "PLb":
                switch (actionState.current.step) {
                    case 1:
                        // Ask user to either select a set or skip to create a new set
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: actionState.current.cardCode,
                                options: [
                                    { id: "SET", name: "Select Set" },
                                    {
                                        id: "SKIP",
                                        name: "Create New Set",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "SET":
                                actionState.current = {
                                    ...actionState.current,
                                    payload: {
                                        set: { set_id: setId },
                                    },
                                };
                                break;
                            case "SKIP":
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            /* Property Wild Cards */
            case "PWCXX":
            case "PWCRdYl":
            case "PWCOrPk":
            case "PWCLbBl":
            case "PWCDgBl":
            case "PWCLgBl":
            case "PWCLbBr":
            case "PWCDbDg":
                // Set ID or color
                switch (actionState.current.step) {
                    case 1:
                        // Ask user to either select a set or select a color
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: actionState.current.cardCode,
                                options: [
                                    { id: "SET", name: "Select Set" },
                                    {
                                        id: "COLOR",
                                        name: "Create New Set",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "SET":
                                actionState.current = {
                                    ...actionState.current,
                                    step: actionState.current.step + 1,
                                    payload: {
                                        set: { set_id: setId },
                                    },
                                };
                                await _playCard();
                                break;
                            case "COLOR":
                                await dispatch({
                                    type: dialogUpdated.type,
                                    collapsable: true,
                                    payload: {
                                        open: true,
                                        cardCode: actionState.current.cardCode,
                                        options: allCards[
                                            actionState.current.card_id
                                        ].colors.map((color) => ({
                                            id: color,
                                            name: color,
                                            requestCallback: true,
                                        })),
                                    },
                                });
                                break;
                            default:
                                console.log(
                                    "Something went wrong!",
                                    actionState.current
                                );
                        }
                        break;
                    case 3:
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: { color: selected },
                            },
                        };
                        await _playCard();
                        break;
                    default:
                        console.log(
                            "Something went wrong!",
                            actionState.current
                        );
                }
                break;
            /* Action Cards */
            case "ASD" /* Sly Deal */:
                switch (actionState.current.step) {
                    case 1:
                        toast("Select a card to steal.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                player_id: playerId,
                                card_id: cardId,
                            },
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "AFD" /* Forced Deal */:
                switch (actionState.current.step) {
                    case 1:
                        toast("Select the card you want to swap out.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                swap_card_id: cardId,
                            },
                        };
                        toast("Select the card you want to swap in.");
                        break;
                    case 3:
                        actionState.current.payload = {
                            ...actionState.current.payload,
                            player_id: playerId,
                            card_id: cardId,
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "ADB" /* Deal Breaker */:
                switch (actionState.current.step) {
                    case 1:
                        toast("Select a full set of another player.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                player_id: playerId,
                                set: { set_id: setId },
                            },
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "ADC" /* Debt Collector */:
                switch (actionState.current.step) {
                    case 1:
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                body: "Select a player to collect debt from.",
                                cardCode: actionState.current.cardCode,
                                options: Object.values(game.players)
                                    .filter(
                                        (player) =>
                                            player.player_id !==
                                            privateData.player_id
                                    )
                                    .map((player) => ({
                                        id: player.player_id,
                                        name: player.username,
                                        requestCallback: true,
                                    })),
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                player_id: selected,
                            },
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "AIB" /* Birthday */:
            case "APG" /* Pass Go */:
                await _playCard();
                break;
            /* Rent Card (Two Colors) */
            case "ARTDbDg":
            case "ARTLbBr":
            case "ARTLgBl":
            case "ARTOrPk":
            case "ARTRdYl":
                switch (actionState.current.step) {
                    case 1:
                        toast("Select a property set to collect rent on.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: { set_id: setId },
                            },
                        };
                        const handCards = Object.values(
                            privateData.private.sets
                        ).filter((set) => set.id.startsWith("hs"))[0].cards;
                        if (
                            handCards.filter((cid) => cid.startsWith("cd_adr"))
                                .length === 0
                        ) {
                            await _playCard();
                            break;
                        }
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: actionState.current.cardCode,
                                options: [
                                    { id: "DOUBLE_RENT", name: "Double Rent" },
                                    {
                                        id: "SKIP",
                                        name: "Skip",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 3:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards =
                                    [cardId];
                                const handCards = Object.values(
                                    privateData.private.sets
                                ).filter((set) => set.id.startsWith("hs"))[0]
                                    .cards;
                                if (
                                    handCards.filter((cid) =>
                                        cid.startsWith("cd_adr")
                                    ).length !== 2
                                ) {
                                    await _playCard();
                                    break;
                                }
                                await dispatch({
                                    type: dialogUpdated.type,
                                    payload: {
                                        open: true,
                                        collapsable: true,
                                        cardCode: actionState.current.cardCode,
                                        options: [
                                            {
                                                id: "DOUBLE_RENT",
                                                name: "Double Rent",
                                            },
                                            {
                                                id: "SKIP",
                                                name: "Skip",
                                                requestCallback: true,
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "SKIP":
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 4:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards.push(
                                    cardId
                                );
                                await _playCard();
                                break;
                            case "SKIP":
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 5:
                        await _playCard();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            /* Rent Card (All Colors) */
            case "ARTXX":
                switch (actionState.current.step) {
                    case 1:
                        toast("Select a property set to collect rent on.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                player_id: playerId,
                                set: { set_id: setId },
                            },
                        };
                        await dispatch({
                            type: dialogUpdated.type,
                            payload: {
                                open: true,
                                collapsable: true,
                                body: "Select a player to collect rent from.",
                                cardCode: actionState.current.cardCode,
                                options: Object.values(game.players)
                                    .filter(
                                        (player) =>
                                            player.player_id !==
                                            privateData.player_id
                                    )
                                    .map((player) => ({
                                        id: player.player_id,
                                        name: player.username,
                                        requestCallback: true,
                                    })),
                            },
                        });
                        break;
                    case 3:
                        // Save input from step 2.
                        actionState.current.payload.player_id = selected;

                        const handCards = Object.values(
                            privateData.private.sets
                        ).filter((set) => set.id.startsWith("hs"))[0].cards;
                        if (
                            handCards.filter((cid) => cid.startsWith("cd_adr"))
                                .length === 0
                        ) {
                            await _playCard();
                            break;
                        }
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: actionState.current.cardCode,
                                options: [
                                    { id: "DOUBLE_RENT", name: "Double Rent" },
                                    {
                                        id: "SKIP",
                                        name: "Skip",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 4:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards =
                                    [cardId];

                                const handCards = Object.values(
                                    privateData.private.sets
                                ).filter((set) => set.id.startsWith("hs"))[0]
                                    .cards;
                                if (
                                    handCards.filter((cid) =>
                                        cid.startsWith("cd_adr")
                                    ).length !== 2
                                ) {
                                    await _playCard();
                                    break;
                                }
                                await dispatch({
                                    type: dialogUpdated.type,
                                    collapsable: true,
                                    payload: {
                                        open: true,
                                        cardCode: actionState.current.cardCode,
                                        options: [
                                            {
                                                id: "DOUBLE_RENT",
                                                name: "Double Rent",
                                            },
                                            {
                                                id: "SKIP",
                                                name: "Skip",
                                                requestCallback: true,
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "SKIP":
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 5:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards.push(
                                    cardId
                                );
                                await _playCard();
                                break;
                            case "SKIP":
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            case "AHT" /* Hotel */:
            case "AHS" /* House */:
                switch (actionState.current.step) {
                    case 1:
                        // Ask user to either select a set or skip to create a new set
                        toast("Select a full set.");
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: { set_id: setId },
                            },
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            case "ADR" /* Double Rent */:
                console.log("Something went wrong!");
                break;
            case "AJN" /* Just Say No */:
                toast("This card cannot initiate an action.");
                actionState.current = null;
                break;
            default:
                toast("This card cannot be played as action card.");
                actionState.current = null;
        }
    };

    const playCard = async (cardId, cardCode, setId, playerId, selected) => {
        if (!actionState.current) {
            actionState.current = {
                card_id: cardId,
                cardCode: cardCode,
            };
            const handCards = Object.values(privateData.private.sets).filter(
                (set) => set.id.startsWith("hs")
            )[0].cards;
            const propertySets = Object.values(
                game.players[privateData.player_id].public.sets
            );
            const bankCards = Object.values(privateData.private.sets).filter(
                (set) => set.id.startsWith("bk")
            )[0].cards;

            let options = [];
            if (handCards.includes(cardId)) {
                if (cardCode.startsWith("M")) {
                    options = [
                        { id: "CASH", name: "Cash", requestCallback: true },
                        {
                            id: "DISCARD",
                            name: "Discard",
                            requestCallback: true,
                        },
                    ];
                } else if (cardCode.startsWith("P")) {
                    options = [
                        { id: "ACTION", name: "Action", requestCallback: true },
                        {
                            id: "DISCARD",
                            name: "Discard",
                            requestCallback: true,
                        },
                    ];
                } else {
                    options = [
                        { id: "CASH", name: "Cash", requestCallback: true },
                        { id: "ACTION", name: "Action", requestCallback: true },
                        {
                            id: "DISCARD",
                            name: "Discard",
                            requestCallback: true,
                        },
                    ];
                }
            } else if (
                propertySets.find((p) => p.cards.includes(cardId)) ||
                bankCards.includes(cardId)
            ) {
                options = [
                    {
                        id: "REARRANGEMENT",
                        name: "Rearrange",
                        requestCallback: true,
                    },
                ];
            }

            await dispatch({
                type: dialogUpdated.type,
                payload: {
                    open: true,
                    collapsable: true,
                    cardCode: actionState.current.cardCode,
                    options: options,
                },
            });
            return;
        }

        if (!actionState.current.play_as)
            actionState.current.play_as = selected;

        switch (actionState.current.play_as) {
            case "CASH":
                _playCashCard();
                break;
            case "REARRANGEMENT":
                _rearrangeCard(setId, selected);
                break;
            case "DISCARD":
                _discardCard();
                break;
            case "ACTION":
            default:
                _playActionCard(cardId, cardCode, setId, playerId, selected);
                break;
        }
    };

    const _ackAction = async () => {
        const { card_id, play_as, payload } = reactionState.current;
        await socketRef.current.emit(
            "action",
            { card_id, play_as, payload },
            (response) => {
                console.log(response);
                toast(response.message);
            }
        );
        reactionState.current = null;
        dispatch({
            type: cardsSelected.type,
            payload: [],
        });
    };

    const ackAction = async (
        actionName,
        cardId,
        targetAmount,
        selected,
        message
    ) => {
        if (!reactionState.current) {
            reactionState.current = {
                play_as: "REPLY",
                actionCard: cardId,
                actionName,
                targetAmount,
                step: 1,
            };
        } else {
            reactionState.current.step += 1;
        }

        const AJNCards = getAJNCards(privateData);

        switch (reactionState.current.actionName) {
            case "SLY_DEAL":
            case "FORCED_DEAL":
            case "DEAL_BREAKER":
                switch (reactionState.current.step) {
                    case 1:
                        // Ask user to either play just say no or ack
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: false,
                            payload: {
                                open: true,
                                cardCode:
                                    allCards[reactionState.current.actionCard]
                                        .code,
                                body: message,
                                options: AJNCards.length
                                    ? [
                                          {
                                              id: "JSN",
                                              name: "Just Say No",
                                              requestCallback: true,
                                          },
                                          {
                                              id: "ACK",
                                              name: "Acknowledge",
                                              requestCallback: true,
                                          },
                                      ]
                                    : [
                                          {
                                              id: "ACK",
                                              name: "Acknowledge",
                                              requestCallback: true,
                                          },
                                      ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "JSN":
                                reactionState.current = {
                                    ...reactionState.current,
                                    card_id: AJNCards[0],
                                };
                                break;
                            case "ACK":
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    reactionState.current
                                );
                                reactionState.current = null;
                        }
                        await _ackAction();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong!",
                            reactionState.current
                        );
                        reactionState.current = null;
                }
                break;
            case "DEBT_COLLECTOR":
            case "ITS_MY_BIRTHDAY":
            case "RENT":
                // Skip to step 2 if the user has no JSN card
                if (!AJNCards.length && reactionState.current.step == 1) {
                    reactionState.current.step += 1;
                    selected = "PAY";
                }
                switch (reactionState.current.step) {
                    case 1:
                        // Ask user to either play just say no or ack
                        await dispatch({
                            type: dialogUpdated.type,
                            payload: {
                                open: true,
                                collapsable: false,
                                cardCode: null,
                                body: message,
                                options: [
                                    {
                                        id: "JSN",
                                        name: "Just Say No",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "PAY",
                                        name: "Pay",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "JSN":
                                reactionState.current = {
                                    ...reactionState.current,
                                    card_id: AJNCards[0],
                                };
                                await _ackAction();
                                break;
                            case "PAY":
                                await dispatch({
                                    type: cardPickerUpdated.type,
                                    payload: {
                                        open: true,
                                        collapsable: false,
                                        title: message,
                                        body: "Select cards to pay.",
                                        targetAmount:
                                            reactionState.current.targetAmount,
                                        options: [
                                            {
                                                id: "PAY",
                                                name: "PAY",
                                                requestCallback: true,
                                            },
                                        ],
                                    },
                                });
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    reactionState.current
                                );
                                reactionState.current = null;
                        }
                        break;
                    case 3:
                        switch (selected) {
                            case "PAY":
                                reactionState.current.payload = {
                                    card_ids: selectedCards,
                                };
                                await _ackAction();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    reactionState.current
                                );
                                reactionState.current = null;
                        }
                }
                break;
            default:
                if (cardId) {
                    await dispatch({
                        type: dialogUpdated.type,
                        payload: {
                            open: true,
                            cardCode: allCards[cardId].code,
                            body: message,
                        },
                    });
                }
                console.log("Unrecognised reaction!", reactionState.current);
                reactionState.current = null;
        }
    };

    const startGame = async (roomId, callback) => {
        socketRef.current.emit(
            "start_game",
            {
                room_id: roomId,
            },
            (res) => {
                console.log(res);
                callback(res);
            }
        );
    };

    const terminateAction = () => {
        actionState.current = null;
    };

    const skipTurn = () => {
        socketRef.current.emit(
            "action",
            {
                skip_turn: true,
                play_as: "ACTION",
            },
            (res) => {
                console.log(res);
                toast(res.message);
            }
        );
    };

    const getCashDrawer = () => {
        return {
            playerId: privateData.player_id,
            propertySets: Object.values(
                game.players[privateData.player_id].public.sets
            ),
            bankCards: Object.values(privateData.private.sets).filter((set) =>
                set.id.startsWith("bk")
            )[0].cards,
            bankId: Object.values(privateData.private.sets).filter((set) =>
                set.id.startsWith("bk")
            )[0].id,
        };
    };

    useEffect(() => {
        if (!socketRef.current) {
            const authToken = localStorage.getItem("auth");
            socketRef.current = io("https://api.dealmonopoly.com", {
                path: "/socket/game-service",
                extraHeaders: {
                    Authorization: authToken,
                },
            });

            socketRef.current.on("gamedata", (response) => {
                console.log("Game data listener received: ", response);
                if (!response) return;
                // If the response has player_id field, it is private data
                if (response.player_id) {
                    dispatch({
                        type: privateDataFetched.type,
                        payload: response,
                    });
                } else
                    dispatch({
                        type: publicDataFetched.type,
                        payload: response,
                    });
                if (!localStorage.getItem("gameStarted")) {
                    localStorage.setItem("gameStarted", true);
                    window.location.reload();
                }
            });

            socketRef.current.on("metadata", (response) => {
                console.log("Meta data listener received: ", response);
                dispatch({
                    type: cardsFetched.type,
                    payload: response,
                });
            });

            socketRef.current.on("notification", (response) => {
                console.log("Notification listener received: ", response);
                toast(response.message);
                socketRef.current.emit("notification", {
                    notification_id: response.id,
                });
            });

            socketRef.current.on("action", (response) => {
                console.log("Action listener received:", response);
                dispatch({
                    type: actionReceived.type,
                    payload: response,
                });
            });

            socketRef.current.on("players", async (response) => {
                await dispatch({
                    type: peersFetched.type,
                    payload: response,
                });
                console.log("Players: ", response);
            });

            socketRef.current.on("connect", () => {
                console.log(`Connected: ${socketRef.current.id}`);
            });

            socketRef.current.on("connect_error", (reason) => {
                console.log(`Connection error: ${reason}`);
                // Maybe the token has expired, reset and retry
                if (localStorage.getItem("auth")) {
                    // TODO: if error message shows that its an expired token, clear else retry
                    // TODO: below goes in if part
                    localStorage.removeItem("auth");
                    localStorage.removeItem("gameStarted");
                    window.location.reload();
                    // TODO: below goes in else part
                    // setTimeout(() => {
                    //   socketRef.current.connect();
                    // }, 1000);
                }
            });

            socketRef.current.on("disconnect", (reason) => {
                console.log(`Disconnected: ${reason}`);
            });

            // socketRef.current.onAny((eventName, ...args) => {
            //   console.log(eventName, args);
            // });
        } else if (
            allCards &&
            game &&
            privateData &&
            action &&
            !action.is_fulfilled &&
            game.turn.active_action
        ) {
            // Ack action only if this user is liable
            if (
                game.turn.active_action.liable_players &&
                game.turn.active_action.liable_players.filter(
                    (player) => player.player_id === privateData.player_id
                )[0]
            ) {
                ackAction(
                    action.action_name,
                    action.action_card,
                    action.value,
                    null,
                    action.message
                );
            }
        }
    }, [allCards, game, privateData, action]);

    return (
        <WebSocketContext.Provider
            value={{
                playCard,
                terminateAction,
                ackAction,
                skipTurn,
                startGame,
                getCashDrawer,
            }}
        >
            {props.children}
        </WebSocketContext.Provider>
    );
};

export default WebSocketProvider;
