export const getHandCards = (privateData) =>
    Object.values(privateData.private.sets).filter((set) =>
        set.id.startsWith("hs")
    )[0].cards;

export const getBankCards = (privateData) =>
    Object.values(privateData.private.sets).filter((set) =>
        set.id.startsWith("bk")
    )[0].cards;

export const getPropertySets = (publicData, playerId) =>
    Object.values(publicData.players[playerId].public.sets);

/* Get Just Say No cards possessed by the user */
export const getAJNCards = (privateData) =>
    getHandCards(privateData).filter((card) => card.startsWith("cd_ajn"));

/* Get Double Rent cards possessed by the user */
export const getADRCards = (privateData) =>
    getHandCards(privateData).filter((card) => card.startsWith("cd_adr"));
