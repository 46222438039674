// import { Fragment, useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
// import { Button } from "@material-ui/core";

import { WebSocketContext } from "../../WebSocket.js";

import "../GameContainer/GameContainer.css";
import "../Card/Card.css";

const Card = ({
    cardId,
    type,
    code,
    setId,
    playerId,
    zoom,
    cash,
    expanded,
    onCardClick,
    selected,
}) => {
    const currPlayerId = useSelector(
        (state) => state.entities.game.public.turn.turn
    );
    const viewerId = useSelector(
        (state) => state.entities.game.private.player_id
    );
    const _selected = useSelector((state) => state.ui.dialog.selected);
    const ws = useContext(WebSocketContext);

    const playCard = () => {
        ws.playCard(cardId, code, setId, playerId, _selected);
    };

    const ackAction = () => {
        ws.ackAction(null, cardId, null, _selected);
    };

    return (
        <div
            className={
                "child-" +
                type +
                "-stack" +
                (cash ? " child-cash-stack" : "") +
                (expanded
                    ? " enlarged-" +
                      type +
                      "-stack-card" +
                      (cash ? " enlarged-cash-card" : "")
                    : "")
            }
        >
            <img
                onClick={() =>
                    onCardClick
                        ? onCardClick(cardId)
                        : viewerId === currPlayerId
                        ? playCard()
                        : ackAction()
                }
                alt={cardId}
                className={
                    "card-img card" +
                    (expanded ? " enlarged-card-image" : "") +
                    (selected ? " card-selected" : "") +
                    (zoom === "2_0" ? " card-zoomed" : "")
                }
                src={"/images/cards/" + (code ? code : "M00") + ".svg"}
            />
        </div>
    );
};

export default Card;
