import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import Window from "../Window/Window.js";
import Prompt from "../Prompt/Prompt.js";
import CardPicker from "../CardPicker/CardPicker.js";
import Container from "../Container/Container.js";
import Loading from "../Loading/Loading.js";
import FloatingMenu from "../FloatingMenu/FloatingMenu.js";
import Modal from "../Modal/Modal.js";
import setupClickHandlers from "../../utils/drag";

import "./GameContainer.css";
import "react-toastify/dist/ReactToastify.css";

const GameContainer = () => {
    const gamePrivateData = useSelector((state) => state.entities.game.private);
    const gamePublicData = useSelector((state) => state.entities.game.public);
    const cardDetails = useSelector((state) => state.entities.cards.all);

    useEffect(() => {
        setupClickHandlers();
    });

    if (gamePrivateData && gamePublicData && Object.keys(cardDetails).length) {
        const viewerId = gamePrivateData.player_id;
        let players = Object.values(gamePublicData.players).map((player) => {
            return player.player_id === viewerId
                ? {
                      playerId: player.player_id,
                      playerName: player.username,
                      propertySets: Object.values(player.public.sets),
                      handCards: Object.values(
                          gamePrivateData.private.sets
                      ).filter((set) => set.id.startsWith("hs"))[0].cards,
                      bankCards: Object.values(
                          gamePrivateData.private.sets
                      ).filter((set) => set.id.startsWith("bk"))[0].cards,
                      bankId: Object.values(
                          gamePrivateData.private.sets
                      ).filter((set) => set.id.startsWith("bk"))[0].id,
                      handId: Object.values(
                          gamePrivateData.private.sets
                      ).filter((set) => set.id.startsWith("hs"))[0].id,
                  }
                : {
                      playerId: player.player_id,
                      playerName: player.username,
                      propertySets: Object.values(player.public.sets),
                      handCards: null,
                      bankCards: player.public.bank.total_cards
                          ? new Array(player.public.bank.total_cards - 1)
                                .fill([null])
                                .flat()
                                .concat([player.public.bank.top_card])
                          : [],
                      bankId: null,
                      handId: null,
                  };
        });

        const winnerId = gamePublicData.winner;
        const isWinner = winnerId === viewerId;
        const losers = players
            .filter((player) => player.playerId !== winnerId)
            .map((player) => player.playerName);
        const winners = players
            .filter((player) => player.playerId === winnerId)
            .map((player) => player.playerName);

        const GameComponent = (
            <Fragment>
                <Modal
                    open={winnerId != null}
                    image={
                        isWinner ? "/images/winner.gif" : "/images/loser.gif"
                    }
                    title={
                        isWinner ? "You won the game!" : "You lost the game!"
                    }
                    texts={[
                        "Winner: " + winners.join(", "),
                        "Loser(s): " + losers.join(", "),
                    ]}
                />
                {players.map((player) => (
                    <Window
                        winnerId={winnerId}
                        key={player.playerId}
                        turn={gamePublicData.turn}
                        viewerId={viewerId}
                        player={player}
                    />
                ))}
                <Prompt />
                <CardPicker />
                <FloatingMenu />
            </Fragment>
        );

        return <Container content={GameComponent} />;
    } else {
        return <Container content={Loading} />;
    }
};

export default GameContainer;
