import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "ui",
    initialState: {
        cardPicker: {
            open: false,
        },
        dialog: {
            open: false,
        },
        loading: false,
        isLogoutPhase: false,
        authenticated: true,
        peersVisible: true,
        windowExpanded: {},
        windowMinimised: {},
    },
    reducers: {
        // action => action handler
        dialogUpdated: (ui, action) => {
            ui.dialog = action.payload;
        },

        loadingShown: (ui) => {
            ui.loading = true;
        },

        loadingHidden: (ui) => {
            ui.loading = false;
        },

        setIsLogoutPhase: (ui, action) => {
            ui.isLogoutPhase = action.payload;
        },

        setAuthenticated: (ui, action) => {
            ui.authenticated = action.payload;
        },

        setWindowExpanded: (ui, action) => {
            ui.windowExpanded[action.payload.windowId] = ui.windowExpanded[
                action.payload.windowId
            ]
                ? !ui.windowExpanded[action.payload.windowId]
                : true;
        },

        setWindowMinimised: (ui, action) => {
            ui.windowMinimised[action.payload.windowId] = ui.windowMinimised[
                action.payload.windowId
            ]
                ? !ui.windowMinimised[action.payload.windowId]
                : true;
        },

        cardPickerUpdated: (ui, action) => {
            ui.cardPicker = action.payload;
        },
    },
});

export const {
    dialogUpdated,
    loadingShown,
    loadingHidden,
    setIsLogoutPhase,
    setIsAuthToggleButton,
    setWindowExpanded,
    setWindowMinimised,
    cardPickerUpdated,
} = slice.actions;

export default slice.reducer;
